import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  SET_USER_DETAILS,
} from '../../../../@jumbo/constants/ActionTypes';
import { deleteUser, getUsers, setCurrentUser } from '../../../../redux/actions/Users';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import useStyles from './index.style';
import axios from '../../../../services/auth/jwt/config';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/SubAdmin';

const UserDetailView = ({ open, onCloseDialog }) => {
  const [profiledata, setProfiledata] = useState({});
  const [activeButton, setActiveButton] = useState('');
  const classes = useStyles();
  const { currentUser } = useSelector(({ SubAdminReducer }) => SubAdminReducer);
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ SubAdminReducer }) => SubAdminReducer);
  const dispatch = useDispatch();
  const {
    name,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    first_name,
    last_name,
    _id,
  } = currentUser;
  /*user fetch option */
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .get(`admin/user-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data);
          console.log('userview', data.data.data.status);
          data.data.data.admin_status === 1 ? setActiveButton('Deactivate') : setActiveButton('ACTIVATE');
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }, _id);

  const handleActive = () => {
    dispatch(updateUserStatus({ id: _id, status: 0, page: users.Page,admin:true }));
    setActiveButton('LOADING');
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    setTimeout(function(){ axios
      .get(`admin/user-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data);
          data.data.data.admin_status === 1 ? setActiveButton('Deactivate') : setActiveButton('ACTIVATE');
          console.log('userview', data.data.data.status);
          // dispatch(fetchSuccess('User status was updated successfully.'));
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      }); }, 1000);

  };
  const deactiveActive = () => {
    dispatch(updateUserStatus({ id: _id, status: 1, page: users.Page , admin:true}));
    setActiveButton('LOADING');
    
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    setTimeout(function(){ axios
      .get(`admin/user-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data);
          data.data.data.admin_status === 1 ? setActiveButton('Deactivate') : setActiveButton('ACTIVATE');
          console.log('userview', data.data.data.status);
          // dispatch(fetchSuccess('User status was updated successfully.'));
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      }); }, 1000);
    
    
  };
  /*user fetch option */
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {' '}
                {profiledata.first_name} {profiledata.last_name}
              </Typography>
            </Box>

            {(designation || company) && (
              <Box mt={-1}>
                {designation && <Typography className={classes.subTitleRoot}>{designation}</Typography>}
                {company && <Typography className={classes.subTitleRoot}>@{company}</Typography>}
              </Box>
            )}
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          User Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
         
          <Box ml={5} component="p" className="pointer">
            <label>First name</label> : {profiledata.first_name}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
         
          <Box ml={5} component="p" className="pointer">
            Last name : {profiledata.last_name}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
         
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Email : {email}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <CheckCircleOutline color="primary" />
          <Box ml={5}>{profiledata.user_type === 2 ? 'Uploader' : 'Admin'}</Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Status : {profiledata.admin_status === 1 ? 'Active' : 'Inactive'}
          </Box>
        </Box>
        <Box ml={5}>
          {authUser.super_admin === true ? (
            profiledata.admin_status === 1 ? (
              <Button variant="contained" color="primary" onClick={handleActive}>
                {activeButton}
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={deactiveActive}>
                {activeButton}
              </Button>
            )
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
