import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import axios from '../../../services/auth/jwt/config';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AboutUpdate from './AboutUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicy,setCurrentPolicy } from '../../../redux/actions/Policy';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import { getDelete } from 'redux/actions/About';
const breadcrumbs = [
  { label: 'Dashboard', link: '/home' },
  { label: 'Content Manager', link: '/contentmanager' },
  { label: 'Privacy Policy', isActive: true },
];
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '90ch',
    },
  },
}));
const initialValues = {
  header: '',
  content: '',
};
function Policy() {
  const { policy } = useSelector(({ PolicyReducer }) => PolicyReducer);
  const { currentpolicy } = useSelector(({ PolicyReducer }) => PolicyReducer);
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [aboutData, setAboutData] = useState([]);
  const [currentId, setCurrentId] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPolicy());
  }, dispatch);

  const handleChange = e => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleClick = event => {
    if (values !== '') {
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .post('content/add-content/', { type: 3, header: values.header, content: values.content })
        .then(
          response => dispatch(getPolicy()),
          setValues({
            header: '',
            content: '',
          }),
        )
        .catch(error => {
          console.log('error', error);
        });
    }
  };
  console.log('initialvalue', policy);
  console.log('currentAbout', currentpolicy);
  return (
    <PageContainer heading="Privacy Policy" breadcrumbs={breadcrumbs}>
      <GridContainer>
        {policy?.length ?<CmtCard style={{ marginTop: '10px' }}>
          <CmtCardHeader title="Edit Privacy Policy content" />
          <CmtCardContent>
            <Box alignItems="center" mb={{ xs: 4, sm: 6 }}>
              {(policy ?? []).map(item => {
                return (
                  <div key={item._id} >
                    {''}
                    <Grid item xs={12} sm={12} xl={12}>
                      <div className={classes.root} style={{marginTop:'20px'}}>
                        <TextField
                          id="outlined-textarea"
                          label="Question"
                          placeholder={item.header}
                          value={item.header}
                          multiline
                          variant="outlined"
                          name={item._id}
                          disabled
                        />
                        </div>
                        <div className={classes.root} style={{marginTop:'20px'}}>
                        <TextField
                          id="outlined-textarea"
                          label="Answer"
                          placeholder={item.content}
                          value={item.content}
                          multiline
                          variant="outlined"
                          name={item._id}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={4} xl={3}>
                    <div className={classes.root} style={{marginLeft:'5px',marginTop:'15px'}}>
                    <NavLink to="/policyupdate" style={{ color: 'white' }}>
                      <Button
                        onClick={e => {
                          dispatch(setCurrentPolicy(item));
                        }}
                        variant="contained"
                        color="primary"
                        value={item}
                        style={{marginRight:'10px'}}>
                        EDIT
                       {' '}
                      </Button>
                      </NavLink>
                      <span >
                      <Button
                        onClick={e => {
                          dispatch(getDelete(item._id));
                        }}
                        variant="contained"
                        color="danger"
                        value={item}>
                        DELETE
                       {' '}
                      </Button> 
                      </span>
                      </div>
                    </Grid>
                  </div>
                );
              })}
            </Box>
          </CmtCardContent>
        </CmtCard>:null}
        <CmtCard style={{ marginTop: '10px' }}>
          <CmtCardHeader title="Add new Privacy Policy content" />
          <CmtCardContent>
            <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <TextField
                style={{ width: '90ch' }}
                id="outlined-textarea"
                label="Header"
                placeholder={values.header}
                value={values.header}
                onChange={handleChange}
                multiline
                variant="outlined"
                name="header"
              />
              </Box>
               <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
              <TextField
                style={{ width: '90ch' }}
                id="outlined-textarea"
                label="Content"
                placeholder={values.content}
                value={values.content}
                onChange={handleChange}
                multiline
                variant="outlined"
                name="content"
              />
            </Box>
            <Grid item xs={6} sm={4} xl={3}>
              <Button onClick={handleClick} variant="contained" color="primary">
                SAVE
              </Button>
            </Grid>
          </CmtCardContent>
        </CmtCard>
      </GridContainer>
    </PageContainer>
  );
}

export default Policy;
