import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NewSubscribers from './Pages/SamplePage/index';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import UsersModule from './modules/Users/index';
import Profile from './Profile/index';
import SubAdmin from './modules/SubAdmin/SubAdmin';
import SubAdminModule from './modules/SubAdmin/index';
import LanguagesList from './modules/LanguagesList/index';
import ProjectList from './modules/ProjectList/index';
import ContactUS from './modules/ContactUS';
import ContentManager from './modules/ContentManager/ContentManger';
import About from './modules/ContentManager/About';
import AboutUpdate from './modules/ContentManager/AboutUpdate';
import TermAndCondition from './modules/ContentManager/Term';
import TermUpdate from './modules/ContentManager/TermUpdate';
import Policy from './modules/ContentManager/Policy';
import PolicyUpdate from './modules/ContentManager/PolicyUpdate';
import FAQ from './modules/ContentManager/FAQ';
import FAQUpdate from './modules/ContentManager/FAQUpdate';
import ProjectUserView from './modules/ProjectList/UserDetailView/ProjectUserDeatail';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/home'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/home'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/home" component={NewSubscribers} />
        <RestrictedRoute path="/users" component={UsersModule} />
        <RestrictedRoute path="/language" component={LanguagesList} />
        <RestrictedRoute path="/project" component={ProjectList} />
        <RestrictedRoute path="/contactus" component={ContactUS} />
        <RestrictedRoute path="/contentmanager" component={ContentManager} />
        <RestrictedRoute path="/about" component={About} />
        <RestrictedRoute path="/termandcondition" component={TermAndCondition} />
        <RestrictedRoute path="/policy" component={Policy} />
        <RestrictedRoute path="/faq" component={FAQ} />
        <Route path="/signin" component={Login} />
        <RestrictedRoute path="/subadmin" component={SubAdminModule} />
        <RestrictedRoute path="/aboutupdate" component={AboutUpdate} />
        <RestrictedRoute path="/termupdate" component={TermUpdate} />
        <RestrictedRoute path="/policyupdate" component={PolicyUpdate} />
        <RestrictedRoute path="/projectuserview" component={ProjectUserView} />
        <Route path="/signup" component={Register} />
        <RestrictedRoute path="/faqupdate" component={FAQUpdate} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
