import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import axios from '../../../services/auth/jwt/config';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions/Common';
import { NavLink } from 'react-router-dom';
import { Box } from '@material-ui/core';
import CmtCard from '@coremat/CmtCard';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100ch',
    },
  },
}));
const initialValues = {
  header: '',
  content: '',
};
function AboutUpdate() {
  const { currentAbout } = useSelector(({ AboutUsReducer }) => AboutUsReducer);
  const [values, setValues] = useState(initialValues);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (currentAbout !== null) {
        setValues({
          header: currentAbout.header,
          content: currentAbout.content,
        });
      }
    },
    currentAbout,
    dispatch,
  );
  console.log('updatedabout', currentAbout);
  const handleChange = e => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };
  
  const handleClick = event => {
    if (values !== '') {
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .put(`content/edit-content/${currentAbout._id}`, { type: 1, header: values.header, content: values.content })
        .then(response => dispatch(fetchSuccess('updated successfully.')))
        .catch(error => {
          dispatch(fetchError('There was something issue in responding server.'));
        });
    }
  };
  return (
    <CmtCard>
      <CmtCardHeader title="About Us content" />
      <CmtCardContent>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 6 }}>
          <GridContainer>
            {' '}
            <Grid item xs={12} sm={12} xl={12}>
              <div className={classes.root}>
                <TextField
                  id="outlined-textarea"
                  label="Question"
                  placeholder={values.header}
                  value={values.header}
                  multiline
                  variant="outlined"
                  onChange={handleChange}
                  name="header"
                />
                <TextField
                  id="outlined-textarea"
                  label="answer"
                  placeholder={values.content}
                  value={values.content}
                  multiline
                  variant="outlined"
                  onChange={handleChange}
                  name="content"
                />
              </div>
            </Grid>
            <Grid item xs={6} sm={2} xl={2}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                UPDATE
              </Button>
            </Grid>
            <Grid item xs={6} sm={2} xl={2}>
            <NavLink to="/about" style={{ color: 'white' }}>
              <Button variant="contained" color="primary">
               
                  BACK
                
              </Button>
              </NavLink>
            </Grid>
          </GridContainer>
        </Box>
      </CmtCardContent>
    </CmtCard>
  );
}

export default AboutUpdate;
