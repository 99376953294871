import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  SET_USER_DETAILS,
} from '../../../../@jumbo/constants/ActionTypes';
import { deleteUser, getProject, getUsers, setCurrentUser } from '../../../../redux/actions/Project';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import useStyles from './index.style';
import axios from '../../../../services/auth/jwt/config';
import { Block, CheckCircleOutline, TrendingUpRounded } from '@material-ui/icons';
import { DialogContent, DialogTitle, Tooltip } from '@material-ui/core';
import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/Project';
import ProjectUserView from './ProjectUserDeatail';
import { NavLink } from 'react-router-dom';
import Profile from 'routes/Profile';
import ProjectLanguageView from './ProjectLanguageVie';
import DeleteIcon from '@material-ui/icons/Delete';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import { emailNotValid, requiredMessage } from '@jumbo/constants/ErrorMessages';
import { isValidEmail } from '@jumbo/utils/commonHelper';
import AppSelectBox from '../../../../@jumbo/components/Common/formElements/AppSelectBox';
const UserDetailView = ({ open, onCloseDialog,getParameter }) => {
  const [profiledata, setProfiledata] = useState({});
  const usersList  = useSelector(({ usersReducer }) => usersReducer.AllUserList);
  const { filterOptions, debouncedSearchTerm, rowsPerPage, page, order,orderBy}= getParameter
  console.log(filterOptions, debouncedSearchTerm, rowsPerPage, page, order,orderBy,getParameter )
  const [activeButton, setActiveButton] = useState('');
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogLanguage,setOpenViewDialogLanguage]= useState(false)
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailName, setEmailName] = useState('');
  const [viewStatus, setViewStatus] = useState(false);
  const [languageName,setLanguageName]=useState('');
  const [languageCode, setLanguageCode] = useState('')
  const [languageCountryCode, setLanguageCountryCode] = useState('')
  const [languageTiaportal, setLanguageTiaportal] = useState('')
  const [languageStatus, setLanguageStatus] = useState(false)
  const [addUser,setAddUser]=useState(false)
  const [newUserEmail, setNewUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [addUserEdit,setAddUserEdit]=useState(false)
  const [openOwner,setOpenOwner]=useState(false)
  const [ownerEmail,setOwnerEmail]=useState('')
  const [ownerEmailError,setOwnerEmailError]=useState('')
  const classes = useStyles();
  const { currentUser } = useSelector(({ ProjectReducer }) => ProjectReducer);
  const { users } = useSelector(({ ProjectReducer }) => ProjectReducer);
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const {
    name,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    first_name,
    last_name,
    _id,
  } = currentUser;
  console.log('projectdata',authUser)
  /*user fetch option */
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .get(`project/project-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data.[0]);
          console.log('userview', data.data.data.[0]);
          data.data.data.[0].status === 1 ? setActiveButton('Deactivate') : setActiveButton('Activate');
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }, _id);
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setOpenViewDialogLanguage(false)
    
  };
  console.log('data',profiledata?.owner_edit_permission)
  const handleCloseViewDialogLanguage=()=>{
    setOpenViewDialogLanguage(false);
  }
  const handleView =(e)=>{

setOpenViewDialog(true);
console.log('name', e.target.value)
  }
  const handleActive = () => {
    dispatch(updateUserStatus({ id: _id, status: 0, page: users.Page }));
    setActiveButton('LOADING');
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    setTimeout(function(){ axios
      .get(`project/project-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data.[0]);
          data.data.data.[0].status === 1 ? setActiveButton('Deactivate') : setActiveButton('Activate');
          console.log('userview', data.data.data.status);
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      }); }, 1000);
    
  };
  const deactiveActive = () => {
    dispatch(updateUserStatus({ id: _id, status: 1, page: users.Page }));
    setActiveButton('LOADING');
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    setTimeout(function(){ axios
      .get(`project/project-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data.[0]);
          data.data.data.[0].status === 1 ? setActiveButton('Deactivate') : setActiveButton('Activate');
          console.log('userview', data.data.data.status);
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      }); }, 1000);
    
  };
  const handleValidationOwner=()=>{
    if (!ownerEmail) {
      setOwnerEmailError(requiredMessage);
    } else if (!isValidEmail(ownerEmail)) {
      setOwnerEmailError(emailNotValid);
    }else{
      handleOwnerAdd()
    }
  }
 
  const handleValidation=()=>{
    if (!newUserEmail) {
      setEmailError(requiredMessage);
    } else if (!isValidEmail(newUserEmail)) {
      setEmailError(emailNotValid);
    }else if(newUserEmail==profiledata?.created_by?.email){
      dispatch(fetchError('User already got Access to this project.'))
    }
    else{
      handleUserAdd()
    }
  }
  const handleOwnerAdd = () => {
    dispatch(fetchStart());
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .put(`project/change-owner-project/${_id}`,{
        email:ownerEmail,
      })
      .then(data => {
        if (data.status === 200) {
          if(data.data.message==="Please assign new owner to parent project first."){
            dispatch(fetchError(`Please assign new owner to parent project first.`));}
            else{
          axios
          .get(`project/project-details/${_id}`)
          .then(data => {
            if (data.status === 200) {
              setAddUser(false)
              setProfiledata(data.data.data.[0]);
             setTimeout(() => {
              dispatch(fetchSuccess('New Owner assigned successfully.'));
             }, 2000); 
              setOwnerEmail('')
              setOpenOwner(false)
              // console.log('userview', data.data.data.[0]);
              dispatch(getProject(filterOptions, debouncedSearchTerm, rowsPerPage, page, order,orderBy));
            } else {
              console.log('error');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
        }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          console.log('error');
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server.'));
        console.log('error', error);
      }); 
    
  };
  const handleOwnerEditPermission = () => {
    dispatch(fetchStart());
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .put(`admin/change-owner-permission/${_id}`,{
        owner_edit_permission:profiledata?.owner_edit_permission?false:true,
      })
      .then(data => {
        if (data.status === 200) {
          axios
          .get(`project/project-details/${_id}`)
          .then(data => {
            if (data.status === 200) {
              setAddUser(false)
              setProfiledata(data.data.data.[0]);
              dispatch(fetchSuccess('Owner permission updated successfully.'));
              setOwnerEmail('')
              console.log('userview', data.data.data.[0]);
            } else {
              console.log('error');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          console.log('error');
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server.'));
        console.log('error', error);
      }); 
    
  };
  const handleUserAdd = () => {
    dispatch(fetchStart());
   
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .put(`project/add-user-access/${_id}`,{
        email:newUserEmail,
        edit:addUserEdit
      })
      .then(data => {
        if (data.status === 200) {
          axios
          .get(`project/project-details/${_id}`)
          .then(data => {
            if (data.status === 200) {
              setAddUser(false)
              setProfiledata(data.data.data.[0]);
              dispatch(fetchSuccess('New User added successfully.'));
              setNewUserEmail('')
              console.log('userview', data.data.data.[0]);
            } else {
              console.log('error');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          console.log('error');
        }
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message));
        console.log('error', error);
      }); 
    
  };
  const handleEditAccess = (user) => {
    dispatch(fetchStart());
    const email =user?.email
    const edit=user?.edit? false:true
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .put(`project/edit-user-access/${_id}/${email}/${edit}`)
      .then(data => {
        if (data.status === 200) {
          if(data.data.message==="You can not remove edit access of parent project owner."){
            dispatch(fetchError(`You can not change edit access of owner/user of parent project.`));
          }else{
            
          
          axios
          .get(`project/project-details/${_id}`)
          .then(data => {
            if (data.status === 200) {
              setProfiledata(data.data.data.[0]);
              dispatch(fetchSuccess('User updated successfully.'));
              console.log('userview', data.data.data.[0]);
            } else {
              console.log('error');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          console.log('error');
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server.'));
        console.log('error', error);
      }); 
    
  };
  const handleDelete = (user) => {
    dispatch(fetchStart());
    const email =user?.email
    const view=user?.view? false:true
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .delete(`project/remove-user-access/${_id}/${email}`)
       
      .then(data => {
        if (data.status === 200) {
        
            if(data.data.message==='You can not remove owner of parent project.'){
              setTimeout(() => {
                dispatch(
                  fetchError(`You can not remove owner/user of parent project.`)
                   );  
              }, 2000);
              
            }  
         else{
          axios
          .get(`project/project-details/${_id}`)
          .then(data => {
            if (data.status === 200) {
              setProfiledata(data.data.data.[0]);
              dispatch(fetchSuccess('User removed successfully.'));
              console.log('userview', data.data.data.[0]);
            } else {
              console.log('error');
            }
          })
          .catch(error => {
            console.log('error', error);
          });
          }
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
          console.log('error');
        }
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server.'));
        console.log('error', error);
      }); 
    
  };
  const onCloseDialogAdd =()=>{
    setAddUser(false)
  }
  const onCloseDialogOwner=()=>{
    setOpenOwner(false)
  }
  /*user fetch option */
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}>
                {' '}
                {profiledata.project_name} 
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Project Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          <Box ml={5} component="p" className="pointer">
            <label>Project name</label> : {profiledata.project_name}
          </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            <label>Project ID</label> : {profiledata._id}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          <Box ml={5} component="p" className="pointer">
            <label>Reload standard messages from master List</label> : {profiledata.r_s_m_from_ml? 'Yes':'No'}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          <Box ml={5} component="p" className="pointer">
            Owner : {profiledata?.isDeleted? 'None' : <>{profiledata?.created_by?.first_name } {profiledata?.created_by?.last_name} | Email : {profiledata?.created_by?.email}</>} {' '} 
            <Box  component="p" className="pointer">
       { profiledata?.isDeleted?null:<Typography className={classes.subTitleRoot} >Owner Permission : View : <Checkbox checked={true} color='primary'   inputProps={{ 'aria-label': 'primary checkbox' }} /> | Edit : <Checkbox checked={profiledata?.owner_edit_permission?true:false} color='primary'  onChange={e=>handleOwnerEditPermission()} inputProps={{ 'aria-label': 'primary checkbox' }} />   </Typography> }
        </Box> 
        {authUser?.super_admin?<Button className={classes.subTitleRoot} size="small" onClick={e=>setOpenOwner(true)} variant='outlined'>Assign new owner</Button>:null}
          </Box>
        </Box>
        <Dialog open={openOwner} onClose={onCloseDialogOwner} className={classes.dialogRoot}>
        <DialogTitle className={classes.dialogTitleRoot}>Assign New Owner</DialogTitle>
        
           <Box m={5}  component="p" >
        <Box  mt={5} component="p" >
        <AppSelectBox
                  fullWidth
                  data={usersList?.userList}
                  label="Email"
                  valueKey="email"
                  variant="outlined"
                  labelKey="email"
                  value={ownerEmail}
                  onChange={e => {setOwnerEmail(e.target.value);
                  setOwnerEmailError('')}}
                  helperText={ownerEmailError}
                />
                </Box>
           <Box display="flex" alignItems="center" justifyContent='flex-end' mb={{ xs: 4, sm: 7 }}>
          <Box  mt={5} component="p" className="pointer">
          <Button  style={{ color:'primary',minWidth:'100px'}} className={classes.subTitleRoot}variant="outlined" color="primary" onClick={e=>{setOpenOwner(false);setOwnerEmail('')}}>Cancel</Button></Box>
          <Box ml={5} mt={5} component="p" className="pointer">
          <Button  style={{ color:'white',minWidth:'100px'}} className={classes.subTitleRoot}variant="contained" color="primary" onClick={e=>handleValidationOwner()}>Assign</Button></Box>
          </Box>
          </Box></Dialog>
        <Dialog open={addUser} onClose={onCloseDialogAdd} className={classes.dialogRoot}>
        <DialogTitle className={classes.dialogTitleRoot}>Add User</DialogTitle>
        
           <Box m={5}  component="p" >
        <Box  mt={5} component="p" >
          <AppTextInput
            
            variant="outlined"
            label="Email Address"
            value={newUserEmail}
            onChange={e => {
              setNewUserEmail(e.target.value);
              setEmailError('');
            }}
            helperText={emailError}
          /></Box>
          <Box mt={5}> View <Checkbox checked={true} color='primary'  inputProps={{ 'aria-label': 'primary checkbox' }} /> | Edit : <Checkbox checked={addUserEdit} color='primary' value={addUserEdit} onChange={e=>setAddUserEdit(addUserEdit?false:true)} inputProps={{ 'aria-label': 'primary checkbox' }} /> </Box>
           <Box display="flex" alignItems="center" justifyContent='flex-end' mb={{ xs: 4, sm: 7 }}>
          <Box  mt={5} component="p" className="pointer">
          <Button  style={{ color:'primary',minWidth:'100px'}} className={classes.subTitleRoot}variant="outlined" color="primary" onClick={e=>{setAddUser(false); setNewUserEmail('');setEmailError('');}}>Cancel</Button></Box>
          <Box ml={5} mt={5} component="p" className="pointer">
          <Button  style={{ color:'white',minWidth:'100px'}} className={classes.subTitleRoot}variant="contained" color="primary" onClick={e=>handleValidation()}>Add</Button></Box>
          </Box>
          </Box></Dialog>
        <Box  alignItems="center" mb={{ xs: 4, sm: 7 }}>
      
          <Box ml={5} component="p" className="pointer">
            Users : {profiledata?.isDeleted? null:<Button className={classes.subTitleRoot} size="small"variant='outlined' onClick={e=>setAddUser(true)}>Add new user</Button>}
            {profiledata?.isDeleted? 'None' : <Box mt={2}><Typography className={classes.subTitleRoot}>Name : <Button className={classes.subTitleRoot} size="small"  value={1} onClick={e=>{setOpenViewDialog(true);setFirstName(profiledata?.created_by?.first_name);setLastName(profiledata?.created_by?.last_name);setEmailName(profiledata?.created_by?.email);setViewStatus(profiledata?.created_by?.status);}}>{profiledata?.created_by?.first_name} {profiledata?.created_by?.last_name} (Owner) {authUser?.email==profiledata?.created_by?.email ?'(You)':null}</Button>   | View : <Checkbox checked={true} color='primary'   inputProps={{ 'aria-label': 'primary checkbox' }} /> | Edit : <Checkbox checked={profiledata?.owner_edit_permission?true:false} color='primary' onChange={e=>handleOwnerEditPermission()} inputProps={{ 'aria-label': 'primary checkbox' }} /> | <Button className={classes.subTitleRoot} size="small" disabled variant='outlined'>Remove</Button>  </Typography> 
             </Box>}
            
          {(profiledata.access_user ?? []).map(item =>item.email? <Box mt={2}><Typography className={classes.subTitleRoot}>Name : <Button className={classes.subTitleRoot} size="small"  value={1} onClick={e=>{setOpenViewDialog(true);setFirstName(item.first_name);setLastName(item.last_name);setEmailName(item.email);setViewStatus(item.status);}}>{item.first_name} {item.last_name} {authUser?.email==item?.email ?'(You)':null}</Button>   | View : <Checkbox checked={item?.view} color='primary' value={item}  inputProps={{ 'aria-label': 'primary checkbox' }} /> | Edit : <Checkbox checked={item?.edit} color='primary'  onChange={e=>handleEditAccess(item)} inputProps={{ 'aria-label': 'primary checkbox' }} value={item}/> | <Button className={classes.subTitleRoot} size="small" onClick={e=>handleDelete(item)} variant='outlined'>Remove</Button>  </Typography> 
             </Box>:'')}
            <ProjectUserView open={openViewDialog} onCloseDialog={handleCloseViewDialog} firstName={firstName} lastName={lastName} email={emailName} status={viewStatus}/>
 
          </Box>
          
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
        
          <Box ml={5}  component="p" className="pointer">
        Languages:{(profiledata.languages ?? []).map(item =><Box mt={2}><Typography className={classes.subTitleRoot}>Language name :<Button className={classes.subTitleRoot} size="small"  value={1} onClick={e=>{setOpenViewDialogLanguage(true);setLanguageName(item.name);setLanguageCode(item.language_code);setLanguageCountryCode(item.country_code);setLanguageTiaportal(item.tia_portal);setLanguageStatus(item.status);}}>{item.name} </Button> </Typography>
          <Typography className={classes.subTitleRoot}>Country code : {item.country_code} </Typography>
         </Box>)}
          </Box>
          <ProjectLanguageView open={openViewDialogLanguage} onCloseDialog={handleCloseViewDialog} name={languageName} language_code={languageCode} country_code={languageCountryCode} tia_portal={languageTiaportal} status={languageStatus}/>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          {/* <Box ml={5}  component="p" className="pointer">
        Alarms:{(profiledata.alarms ?? []).map(item =><Box mt={2}><Typography className={classes.subTitleRoot}>Alarm name : {item.alarm_name} </Typography>
          </Box>)}
          </Box> */}
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          <Box ml={5}  component="p" className="pointer">
          Additional alarms:{(profiledata.additional_alarm ? <Box mt={2}><Typography className={classes.subTitleRoot}>Additional alarm name : {profiledata.additional_alarm.a_alarm_name?profiledata.additional_alarm.a_alarm_name:'None'} </Typography></Box>: '')}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Deleted : {profiledata?.isDeleted ? 'Yes' : 'No'}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Status : {profiledata.status === 1 ? 'Active' : 'Inactive'}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          
          <Box ml={5}  component="p" className="pointer">
         
          </Box>
        </Box>

       
        <Box ml={5}>
          {profiledata.status === 1 ? (
            <Button variant="contained" color="primary" onClick={handleActive}>
              {activeButton}
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={deactiveActive}>
              {activeButton}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
