import { GET_POLICY, SET_POLICY } from '../../@jumbo/constants/ActionTypes';
const INIT_STATE = {
  policy: [],
  currentPolicy: null,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POLICY: {
      return {
        ...state,
        policy: action.payload,
      };
    }
    case SET_POLICY: {
      return {
        ...state,
        currentPolicy: action.payload,
      };
    }
    
    default:
      return state;
  }
};
