import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  SET_USER_DETAILS,
} from '../../../../@jumbo/constants/ActionTypes';
import { deleteUser, getUsers, setCurrentUser } from '../../../../redux/actions/Users';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import useStyles from './index.style';
import axios from '../../../../services/auth/jwt/config';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/Users';
import { identity } from 'lodash';
import { NavLink } from 'react-router-dom';



const ProjectLanguageView = ({ open, onCloseDialog , name , language_code,country_code,tia_portal,status}) => {
  const [profiledata, setProfiledata] = useState({});
 

  const classes = useStyles();
  
  

 
  /*user fetch option */
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={name} alt={name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}> {name}</Typography>
            </Box>

          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Language Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            <label>Name</label> : {name}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            Language code : {language_code}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            Country code : {country_code}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            TIA portal : {tia_portal}
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 5 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Status : {status === 1 ? 'Active' : 'Inactive'}
          </Box>
        </Box>
        
      </Box>
    </Dialog>
  );
};

export default ProjectLanguageView;

ProjectLanguageView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
