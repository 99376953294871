import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { timeFromNow } from '../../../../@jumbo/utils/dateHelper';
import { Block, CheckCircleOutline, Delete, Edit, Mail, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/SubAdmin';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    marginBottom: 2,
    fontSize: 14,
    letterSpacing: 0.25,
    color: theme.palette.common.dark,
  },
}));

const getUserActions = (user, authUser, users) => {
  const actions = [{ action: 'view', label: 'View', icon: <Visibility /> }];
  if (authUser.super_admin === true) {
    if (user.admin_status === 1) {
      actions.push({ action: 'suspend', label: 'Deactivate', icon: <Block /> });
    } else {
      actions.push({
        action: 'activate',
        label: 'Reactivate',
        icon: <CheckCircleOutline />,
      });
    }
  }

  return actions;
};

const UserListRow = ({ row, isSelected, onRowClick, onUserEdit, onUserDelete, onUserView }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  const { users } = useSelector(({ SubAdminReducer }) => SubAdminReducer);
  console.log('admin', row);
  const dispatch = useDispatch();

  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      onUserView(row);
    } else if (menu.action === 'edit') {
      onUserEdit(row);
    } else if (menu.action === 'email') {
      dispatch(sentMailToUser());
    } else if (menu.action === 'suspend') {
      dispatch(updateUserStatus({ id: row._id, status: 0, page: users.Page ,admin:true}));
    } else if (menu.action === 'activate') {
      dispatch(updateUserStatus({ id: row._id, status: 1, page: users.Page, admin:true }));
    } else if (menu.action === 'delete') {
      onUserDelete(row);
    }
  };

  const labelId = `enhanced-table-checkbox-${row._id}`;
  const isItemSelected = isSelected(row._id);
  const userActions = getUserActions(row, authUser, users);

  return (
    <TableRow
      hover
      onClick={event => onRowClick(event, row._id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={false}>
      <TableCell padding="checkbox"></TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <Box display="flex" alignItems="center">
          <Box mr={{ xs: 4, md: 5 }}>
            <CmtAvatar size={40} src={row.profile_pic} alt={row.name} />
          </Box>
          <div>
            <Typography className={classes.titleRoot} component="div" variant="h4">
              {row.first_name} {row.last_name}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{row.admin_status === 1 ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>{row.user_type[0] === 1 ? 'Admin' : 'Uploader'}</TableCell>
      {/* <TableCell align="right">{row.emailUsage} GB</TableCell> */}
      <TableCell align="center" onClick={event => event.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
};

export default React.memo(UserListRow);
