import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_FAQ, SET_FAQ } from '../../@jumbo/constants/ActionTypes';
//update faq
export const getFAQ = () => {
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .post('content/get-content/', { type: 4 })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_FAQ, payload: data.data.data });
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(err => {
        
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};

export const setCurrentFAQ = item => {
  return dispatch => {
    dispatch({ type: SET_FAQ, payload: item });
  };
};
