import { GET_ABOUT, SET_ABOUT } from '../../@jumbo/constants/ActionTypes';
const INIT_STATE = {
  about: [],
  currentAbout: null,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ABOUT: {
      return {
        ...state,
        about: action.payload,
      };
    }
    case SET_ABOUT: {
      return {
        ...state,
        currentAbout: action.payload,
      };
    }
    
    default:
      return state;
  }
};
