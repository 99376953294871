import React, { useEffect, useState } from 'react';
import StatisticsModernCard from '../../../@jumbo/components/Common/StatisticsModernCard';
import InfoIcon from '@material-ui/icons/Info';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import axios from '../../../services/auth/jwt/config';
import { NavLink } from 'react-router-dom';

const breadcrumbs = [
  { label: 'Dashboard', link: '/home' },
  { label: 'Content Manager', isActive: true },
];

const ContentManager = () => {
  return (
    <PageContainer heading="Content Manager" breadcrumbs={breadcrumbs}>
      
      <GridContainer>
        <Grid item xs={2} sm={6} xl={4}>
          <NavLink to="/about">
            <StatisticsModernCard
              backgroundColor="#6db6ac"
              titleIcon={<InfoIcon style={{ color: '#fff' }} />}
              title={'About Us'}
              subTitle=""></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={2} sm={6} xl={4}>
          <NavLink to="/termandcondition">
            <StatisticsModernCard
              backgroundColor="#6db6ac"
              titleIcon={<InfoIcon style={{ color: '#fff' }} />}
              title={'Terms & Conditions'}
              subTitle=""></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={2} sm={6} xl={4}>
          <NavLink to="/policy">
            <StatisticsModernCard
              backgroundColor="#6db6ac"
              titleIcon={<InfoIcon style={{ color: '#fff' }} />}
              title={'Privacy Policy'}
              subTitle=""></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={2} sm={6} xl={4}>
          <NavLink to="/faq">
            <StatisticsModernCard
              backgroundColor="#6db6ac"
              titleIcon={<InfoIcon style={{ color: '#fff' }} />}
              title={'FAQ'}
              subTitle=""></StatisticsModernCard>
          </NavLink>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default ContentManager;
