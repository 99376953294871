import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions/Common';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../services/auth/jwt/config';
import CmtImage from '../../@coremat/CmtImage';

import IntlMessages from '../../@jumbo/utils/IntlMessages';

import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ChangePassword = ({ variant = 'default', wrapperVariant = 'default' }) => {
  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableResetbutton, setDisableResetbutton] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  const onSubmit = () => {
  if(oldPassword===newPassword && oldPassword!==''){
    dispatch(fetchError('Old and new password are same. Please use some different password.'));
  }else if(confirmPassword!==newPassword){
    dispatch(fetchError('New password and confirm password is not matching.'));
  }
  else{  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios
    .post(`admin/resetPassword`, {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    })
    .then(data => {
      if (data.status === 200) {
        console.log('password updated', data);
        dispatch(fetchSuccess('Password changed successfully.'));
        setOldPassword('');
        setConfirmPassword('');
        setNewPassword('');
        setDisableResetbutton(true);
      } else {
        dispatch(fetchError('There is something issue in responding server.'));
      }
    })
    .catch(err => {
      if(oldPassword===''){dispatch(fetchError('Old password is required.'));}
     else{dispatch(fetchError(err?.response?.data?.message));
      console.log('Error:',err?.response?.data?.message);} 
    });}
    // dispatch(AuhMethods[method].onForgotPassword({ email }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {!variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              type="password"
              label={<IntlMessages id="Old Password" />}
              fullWidth
              onChange={event => setOldPassword(event.target.value)}
       Value={oldPassword}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              oncopy onpaste drop    
     oncut
            />
            <TextField
              type="password"
              label={<IntlMessages id="New Password" />}
              fullWidth
              onChange={event => setNewPassword(event.target.value)}
              Value={newPassword}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              oncopy onpaste drop    
     oncut
            />
            <TextField
              type="password"
              label={<IntlMessages id="Confirm Password" />}
              fullWidth
              onChange={event => setConfirmPassword(event.target.value)}
              Value={confirmPassword}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              oncopy onpaste drop    
     oncut
            />
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={disableResetbutton}>
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </div>

          <div></div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ChangePassword;
