import React from 'react';
import { PostAdd } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IntlMessages from '../../../utils/IntlMessages';
import PeopleIcon from '@material-ui/icons/People';
import LanguageIcon from '@material-ui/icons/Language';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import TocIcon from '@material-ui/icons/Toc';
export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: 'DashBoard',
        type: 'item',
        icon: <DashboardIcon />,
        link: '/home',
      },
      {
        name: 'Project list',
        type: 'item',
        icon: <AccountTreeIcon />,
        link: '/project',
      },
      {
        name: 'Language list',
        type: 'item',
        icon: <LanguageIcon />,
        link: '/language',
      },
      
      
      {
        name: 'Users',
        type: 'item',
        icon: <PeopleIcon />,
        link: '/users',
      },
      {
        name: 'Sub-Admin',
        type: 'item',
        icon: <PeopleIcon />,
        link: '/subadmin',
      },
      {
        name: 'Profile',
        type: 'item',
        icon: <AccountCircleIcon />,
        link: '/profile',
      },
      {
        name: 'Content Manager',
        type: 'item',
        icon: <TocIcon />,
        link: '/contentmanager',
      },
      {
        name: `"ContactUS" query`,
        type: 'item',
        icon: <ContactSupportIcon />,
        link: '/contactus',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
