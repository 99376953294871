import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  SET_USER_DETAILS,
} from '../../../../@jumbo/constants/ActionTypes';
import { deleteUser, getLanguage, setCurrentUser } from '../../../../redux/actions/Language';
import CmtList from '../../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import Button from '@material-ui/core/Button';
import useStyles from './index.style';
import axios from '../../../../services/auth/jwt/config';
import { Block, CheckCircleOutline } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { sentMailToUser, updateUserStatus } from '../../../../redux/actions/ContactUs';
import moment from 'moment';
const UserDetailView = ({ open, onCloseDialog }) => {
  const [profiledata, setProfiledata] = useState({});

  const classes = useStyles();
  const { currentUser } = useSelector(({ LanguageReducer }) => LanguageReducer);
  const { users } = useSelector(({ LanguageReducer }) => LanguageReducer);
  const dispatch = useDispatch();
  const {
    name,
    email,
    status,
    phones,
    company,
    designation,
    profile_pic,
    starred,
    first_name,
    last_name,
    _id,
  } = currentUser;
  /*user fetch option */
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .get(`contactUs/getcontactUsDetails/${_id}`)
      .then(data => {
        if (data.status === 200) {
          setProfiledata(data.data.data);
          console.log('userview', data.data.data);
        } else {
          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }, [_id]);

  const handleActive = () => {
    dispatch(updateUserStatus({ id: _id, status: 0, page: users.Page }));

    
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .get(`language/alarm-details/${_id}`)
      .then(data => {
        if (data.status === 200) {
          // setProfiledata(data.data.data);
          console.log('userview', data.data.data.status);

          console.log('error');
        }
      })
      .catch(error => {
        console.log('error', error);
      });
   
  };

  /*user fetch option */
  return (
    <Dialog open={open} onClose={onCloseDialog} className={classes.dialogRoot}>
      <Box className={classes.userInfoRoot}>
        <Box mr={3} display="flex" alignItems="center">
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={70} src={profile_pic} alt={name} />
          </Box>

          <Box mt={-2}>
            <Box display="flex" alignItems="center">
              <Typography className={classes.titleRoot}> {profiledata.name}</Typography>{' '}
            </Box>
          </Box>
        </Box>
        <Box ml="auto" mt={-2} display="flex" alignItems="center">
          <Box ml={1}></Box>
          <Box ml={1}>
            <IconButton onClick={onCloseDialog}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box px={6} py={5}>
        <Box mb={5} component="p" color="common.dark">
          Query and User Detail
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            <label>Name</label> : {profiledata.user_id ? profiledata.user_id.first_name : ''}{' '}
            {profiledata.user_id ? profiledata.user_id.last_name : ''}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} component="p" className="pointer">
            Email : {profiledata.user_id ? profiledata.user_id.email : ''}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Query : {profiledata.query}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Status : {profiledata.status === 1 ? 'Open' : 'Closed'}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={{ xs: 4, sm: 7 }}>
          <Box ml={5} color="primary.main" component="p" className="pointer">
            Created at : {profiledata ?  moment(profiledata?.created_at * 1000).format("DD MMM YYYY hh:mm A") : ''}
          </Box>
        </Box>

        <Box ml={5}>
          {profiledata.status === 1 ? (
            <Button variant="contained" color="primary" onClick={handleActive}>
              CLOSE
            </Button>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default UserDetailView;

UserDetailView.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
