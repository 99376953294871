//for getting  mail detail
import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_USER_DETAIL } from '../../@jumbo/constants/ActionTypes';
//update user profile details
export const getUserDetail = () => {
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .get('/admin')
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_USER_DETAIL, payload: data.data });
        } else {
          dispatch(fetchError('Something went wrong'));
        }
      })
      .catch(err => {
        
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};
