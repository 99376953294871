import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_ABOUT, SET_ABOUT } from '../../@jumbo/constants/ActionTypes';
import { getTerm } from './Term';
import { getFAQ } from './FAQ';
import { getPolicy } from './Policy';

// get about us page content
export const getAbout = () => {
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .post('content/get-content/', { type: 1 })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_ABOUT, payload: data.data.data });
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(err => {
        
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};
//delete about us content
export const getDelete = (id) => {
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .delete(`/content/delete-content/${id}`)
      .then(data => {
        if (data.status === 200) {
          
          dispatch(getAbout())
          dispatch(getTerm())
          dispatch(getFAQ())
          dispatch(getPolicy())
          
          setTimeout(function(){ dispatch(fetchSuccess('Deleted successfully.',3000)); }, 1000);
          
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(err => {
        
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};
export const setCurrentAbout = item => {
  return dispatch => {
    dispatch({ type: SET_ABOUT, payload: item });
  };
};
