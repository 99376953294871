import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import { GET_TERM, SET_TERM } from '../../@jumbo/constants/ActionTypes';
//update term and condition
export const getTerm = () => {
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .post('content/get-content/', { type: 2 })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_TERM, payload: data.data.data });
          console.log(data.data.data);
        } else {
          dispatch(fetchError('There was something issue in responding server.'));
        }
      })
      .catch(err => {
       
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};

export const setCurrentTerm = item => {
  return dispatch => {
    dispatch({ type: SET_TERM, payload: item });
  };
};
