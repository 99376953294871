import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions/Common';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../services/auth/jwt/config';
import CmtImage from '../../@coremat/CmtImage';

import IntlMessages from '../../@jumbo/utils/IntlMessages';

import AuthWrapper from '../../@jumbo/components/Common/authComponents/AuthWrapper';
import { getUserDetail } from 'redux/actions/ProfileApp';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ChangeName = ({userDetail}  ) => {
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = useState(userDetail? userDetail.data.first_name:'');
  const [lastName, setLastName] = useState(userDetail? userDetail.data.last_name:'');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableResetbutton, setDisableResetbutton] = useState(false);
  const dispatch = useDispatch();
  const variant = 'default', wrapperVariant = 'default'
  const classes = useStyles({ variant });
  const history = useHistory();
console.log('userdetai',userDetail)
  const onSubmit = () => {
  if(firstName==='' || lastName===''){
    dispatch(fetchError('First name or Last name should not be empty.'));
  }
  
  else{  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios
    .put(`admin/edit-profile`, {
    first_name: firstName,
    last_name: lastName
    })
    .then(data => {
      if (data.status === 200) {
        console.log('password updated', data);
        dispatch(fetchSuccess('Name changed successfully.'));
        dispatch(getUserDetail());
        setDisableResetbutton(true);
        window.location.reload();
      } else {
        dispatch(fetchError('There is something issue in responding server.'));
      }
    })
    .catch(err => {
     
    dispatch(fetchError(err?.response?.data?.message));
      console.log('Error:',err?.response?.data?.message);
    });}
   
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {!variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Change Name
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          <div className={'mb-5'}>
            <TextField
              type="test"
              label='First Name'
              fullWidth
              onChange={event => setFirstName(event.target.value)}
       Value={firstName}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
             placeholder={firstName}
            />
            <TextField
              type="text"
              label='Last Name'
              fullWidth
              onChange={event => setLastName(event.target.value)}
              Value={lastName}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              placeholder={lastName}
            />
           
          </div>
          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={disableResetbutton}>
              Update Profile
            </Button>
          </div>

          <div></div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default ChangeName;
