import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';

const JWTAuth = {
  onRegister: ({ firstName, email, lastName }) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .post('admin/create-sub-admin', {
          first_name: firstName,
          last_name: lastName,
          email: email,
        })
        .then(({ data }) => {
          console.log(data);
          dispatch(fetchSuccess());
          if (data) {
            dispatch(fetchSuccess());
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('admin/login', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            console.log('responce',data.data.status)
            if (data.data.admin_status) {
              console.log('ruutul', data.data);
              localStorage.setItem('refreshtoken', data.data.refresh_tokens);
              localStorage.setItem('token', data.data.tokens);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.tokens;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.data.tokens));
            } else {
              
              dispatch(fetchError('Unauthorized user.'));
              console.log('ifelse')
            }
          })
          .catch(function(err) {
            dispatch(fetchError(err?.response?.data?.message));
            console.log('Error:',err?.response?.data?.message);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
        console.log('mainerror')
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      dispatch(fetchStart());
      axios
        .get('admin/logout')
        .then(({ data }) => {
          if (data) {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      const refreshToken1 = localStorage.getItem('refreshtoken');
      axios
        .post('admin/refresh-token', {
          refreshToken: refreshToken1,
        })
        .then(({ data }) => {
          if (data) {
            console.log('ruutulllllllllll', data);
            localStorage.setItem('token', data.data.authToken);
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            dispatch(fetchStart());
            dispatch(updateLoadUser(loaded));
            axios
              .get('admin/')
              .then(({ data }) => {
                if (data) {
                  console.log(data);
                  dispatch(fetchSuccess());
                  dispatch(setAuthUser(data.data));
                } else {
                  dispatch(updateLoadUser(true));
                }
              })
              .catch(function(error) {
                dispatch(updateLoadUser(true));
              });
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
      if (!token) {
        //old code
        // const refreshToken1 = localStorage.getItem('refreshtoken');
        // dispatch(fetchStart());
        // axios
        //   .post('admin/refresh-token', {
        //     refreshToken: refreshToken1,
        //   })
        //   .then(({ data }) => {
        //     if (data) {
        //       console.log('ruutulllllllllll', data);
        //       localStorage.setItem('token', data.data.authToken);
        //       const token = localStorage.getItem('token');
        //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        //     } else {
        //       dispatch(fetchError(data.error));
        //     }
        //   })
        //   .catch(function(error) {
        //     dispatch(fetchError(error.message));
        //   });
      }
    };
  },

  onForgotPassword: ({ email }) => {
    return dispatch => {
      dispatch(fetchStart());

      try {
        dispatch(fetchStart());
        axios
          .post('admin/forgotPassword', {
            email: email,
          })
          .then(({ data }) => {
            if (data) {
              console.log('ruutul', data);

              dispatch(fetchSuccess(data.message));
              dispatch(setForgetPassMailSent(true));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
