import React, { useEffect, useState } from 'react';
import StatisticsModernCard from '../../../@jumbo/components/Common/StatisticsModernCard';
import GroupIcon from '@material-ui/icons/Group';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import axios from '../../../services/auth/jwt/config';
import { NavLink } from 'react-router-dom';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LanguageIcon from '@material-ui/icons/Language';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';


const fetchDataCall = async () => {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  let apiReturn = await axios
    .get('admin/dashboard/')
    .then(async function(response) {
      return response;
    })
    .catch(function(err) {
      console.log(err);
      if(err?.response?.status===500){
        window.location.reload();
      }
    });
  return apiReturn;
};
const NewSubscribers = () => {
  const [dashboardData, setDashboardData] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      let response = await fetchDataCall();
      setDashboardData(response.data.data);
      console.log('responce', dashboardData);
    };

    fetchData();
    
  }, []);
  console.log('userview', dashboardData);
  return (
    <PageContainer heading="Dashboard" >
      <GridContainer>
        <Grid item xs={12} sm={6} xl={3}>
          <NavLink to="/users">
            <StatisticsModernCard
              backgroundColor="#4db6ac"
              titleIcon={<GroupIcon style={{ color: '#fff' }} />}
              title={dashboardData?.userCount!==null ? dashboardData.userCount : 'Loading'}
              subTitle="Active Users"></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <NavLink to="/subadmin">
            <StatisticsModernCard
              backgroundColor="#4db6ac"
              titleIcon={<GroupIcon style={{ color: '#fff' }} />}
              title={dashboardData?.subAdminCount!==null ? dashboardData.subAdminCount : 'Loading'}
              subTitle="Active Sub-Admin"></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <NavLink to="/project">
            <StatisticsModernCard
              backgroundColor="#4db6ac"
              titleIcon={<AccountTreeIcon style={{ color: '#fff' }} />}
              title={dashboardData?.projectCount!==null ? dashboardData.projectCount : 'Loading'}
              subTitle="Active Projects"></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <NavLink to="/language">
            <StatisticsModernCard
              backgroundColor="#4db6ac"
              titleIcon={<LanguageIcon style={{ color: '#fff' }} />}
              title={dashboardData?.languageCount!==null ? dashboardData.languageCount : 'Loading'}
              subTitle="Active Languages"></StatisticsModernCard>
          </NavLink>
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <NavLink to="/contactus">
            <StatisticsModernCard
              backgroundColor="#4db6ac"
              titleIcon={<ContactSupportIcon style={{ color: '#fff' }} />}
              title={dashboardData?.contactCount!==null ? dashboardData?.contactCount?.toString() : 'Loading'}
              subTitle="Open ContactUs query"></StatisticsModernCard>
          </NavLink>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default NewSubscribers;
