import { GET_FAQ, SET_FAQ } from '../../@jumbo/constants/ActionTypes';
const INIT_STATE = {
  FAQ: [],
  currentFAQ: null,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FAQ: {
      return {
        ...state,
        FAQ: action.payload,
      };
    }
    case SET_FAQ: {
      return {
        ...state,
        currentFAQ: action.payload,
      };
    }
    default:
      return state;
  }
};
