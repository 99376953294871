import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import Users from './Users';
import SubAdmin from './SubAdmin';
import ProfileApp from './ProfileApp';
import Language from './Language';
import Project from './Project';
import ContactUs from './ContactUs';

import AboutUs from './AboutUs';
import Term from './Term';
import Policy from './Policy';
import FAQ from './FAQ';
export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    usersReducer: Users,
    profileApp: ProfileApp,
    SubAdminReducer: SubAdmin,
    LanguageReducer: Language,
    ProjectReducer: Project,
    ContactUsReducer: ContactUs,
    AboutUsReducer: AboutUs,
    TermReducer: Term,
    PolicyReducer: Policy,
    FAQReducer: FAQ,
  });
