import { fetchError, fetchStart, fetchSuccess } from './Common';
import axios from '../../services/auth/jwt/config';
import {
  ADD_USER,
  DELETE_BULK_USERS,
  DELETE_USER,
  EDIT_USER,
  GET_CONTACTUS,
  SET_USER_DETAILS,
} from '../../@jumbo/constants/ActionTypes';
//get contact us query
export const getContactUs = (filterOptions = [], searchTerm = '', rowsPerPage, page, order,orderBy,callbackFun) => {
  return dispatch => {
    window.localStorage.setItem("rowperpage", rowsPerPage);
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const searchtermContact = sessionStorage.getItem('searchtermContact');
    if (searchTerm === '') {
      console.log('seacrhtermpage', searchTerm);
    } else {
      if (searchtermContact === searchTerm) {
        console.log('seacrhtermpage', searchTerm);
      } else {
        page = 0;
        
        sessionStorage.setItem('searchtermContact', searchTerm);
      }
    }
    axios
      .get('contactUs/getContactUsList', {
        params: { filterOptions, limit: rowsPerPage, page: page, sortBy: 'name:asc', q: searchTerm ,sortBy:`${orderBy}:${order}`},
      })
      .then(data => {
        if (data.status === 200) {
          /*search */
          let LanguageData = data.data.data;
         
          /*search */
          dispatch(fetchSuccess());
          dispatch({ type: GET_CONTACTUS, payload: LanguageData });
          if (callbackFun) {
            callbackFun(data.data.data.userData);
            console.log('callback', callbackFun(data.data.data.userData));
          }
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(err => {
       
        if(err?.response?.status===500 || err?.response?.status===401){
          window.location.reload();
        }else{dispatch(fetchError(err?.response?.data?.message));}
      });
  };
};

export const setCurrentUser = user => {
  return dispatch => {
    dispatch({ type: SET_USER_DETAILS, payload: user });
  };
};
// create new sub admin
export const addNewUser = (user, callbackFun) => {
  console.log('newuser', user);
  return dispatch => {
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .post('admin/create-sub-admin', {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('New user is added successfully.'));
          dispatch(getContactUs());
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There is something issue in responding server'));
      });
  };
};
//email notification
export const sentMailToUser = () => {
  return dispatch => {
    dispatch(fetchSuccess('Email has been sent to user successfully'));
  };
};
//update user
export const updateUser = (user, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users', user)
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was updated successfully.'));
          dispatch({ type: EDIT_USER, payload: data.data });
          if (callbackFun) callbackFun(data.data);
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There is something issue in responding server'));
      });
  };
};
//update user status
export const updateUserStatus = (data, callbackFun) => {
  return dispatch => {
    console.log('status', data);
    dispatch(fetchStart());
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios
      .put(`contactUs/closeContactRequest/${data.id}`, { status: data.status })
      .then(response => {
        if (response.status === 200) {
          dispatch(getContactUs('', '', 10, data.page));
          dispatch(fetchSuccess('Query status is updated successfully.', 3000))
          console.log('subadmin refreshed');
          if (callbackFun) callbackFun(response.data);
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .then(response => {
        setTimeout(function(){ dispatch(fetchSuccess('Query status is updated successfully.', 3000)); }, 1000);
        
      })
      .catch(error => {
        dispatch(fetchError('There is something issue in responding server'));
      });
  };
};
//delete multiple user
export const deleteBulkUsers = (userIds, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .put('/users/bulk-delete', { userIds })
      .then(response => {
        if (response.status === 200) {
          dispatch(fetchSuccess('Selected users were deleted successfully.'));
          dispatch({ type: DELETE_BULK_USERS, payload: userIds });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There is something issue in responding server'));
      });
  };
};
//delete user
export const deleteUser = (userId, callbackFun) => {
  return dispatch => {
    dispatch(fetchStart());
    axios
      .delete('/users', { params: { id: userId } })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess('Selected user was deleted successfully.'));
          dispatch({ type: DELETE_USER, payload: userId });
          if (callbackFun) callbackFun();
        } else {
          dispatch(fetchError('There is something issue in responding server.'));
        }
      })
      .catch(error => {
        dispatch(fetchError('There is something issue in responding server'));
      });
  };
};
