import { GET_TERM, SET_TERM } from '../../@jumbo/constants/ActionTypes';
const INIT_STATE = {
  term: [],
  currentTerm: null,
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TERM: {
      return {
        ...state,
        term: action.payload,
      };
    }
    case SET_TERM: {
      return {
        ...state,
        currentTerm: action.payload,
      };
    }
   
    default:
      return state;
  }
};
